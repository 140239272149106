import React, { FC } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import { mobileMQ } from '@config/mediaQueries';

const Container = styled.div(
  adaptForLargeScreen({
    whiteSpace: 'pre-wrap',
    p: { marginBottom: '1em' },
    'p:last-child': { marginBottom: 0 },
    a: { textDecoration: 'underline' },
    em: { fontFamily: 'F37-Beckett-DemiBoldItalic', fontStyle: 'normal' },

    h6: {
      fontWeight: 'normal',
      margin: 0,
      fontFamily: 'F37 Beckett',

      [mobileMQ]: {
        fontSize: 20,
      },
    },

    h1: {
      fontWeight: 'normal',
      fontFamily: 'F37-Beckett-Bold',
      margin: 0,
      fontSize: 60,
      lineHeight: 1.47,
      paddingBottom: 20,

      [mobileMQ]: {
        fontSize: 40,
      },
    },

    h2: {
      fontWeight: 'normal',
      margin: 0,
      fontFamily: 'F37-Beckett-Thin',
      fontSize: 40,
      paddingBottom: 20,

      [mobileMQ]: {
        lineHeight: 1.25,
      },
    },

    h3: {
      fontWeight: 'normal',
      margin: 0,
      fontFamily: 'F37-Beckett-Bold',
      fontSize: 30,

      [mobileMQ]: {
        marginTop: 20,
        marginBottom: 5,
        lineHeight: 1.25,
      },
    },

    ul: {
      marginTop: 0,
      paddingLeft: 0,
      marginLeft: 10,

      li: {
        listStyleType: 'none',
        paddingBottom: 10,
        textIndent: -18,
        marginLeft: 10,
      },

      'li:before': {
        content: '"–"',
        marginRight: 10,
      },
    },
  }),
);

export const StyledMarkdown: FC<ReactMarkdown.ReactMarkdownProps> = props => {
  const { renderers, ...restProps } = props;

  const allRenderers = {
    ...renderers,
    link: (props: any) => (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    ),
  };

  return (
    <Container>
      <ReactMarkdown {...restProps} renderers={allRenderers} />
    </Container>
  );
};
